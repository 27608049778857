











































































import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VueEditor } from 'vue2-editor';
import axios from 'axios';

import ActiveModal from '@/share/Util/ActiveModal';
import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

import Loading from '@/components/Loading/Loading.vue';
import FeedbackModal from '../FeedbackModal/FeedbackModal.vue';

import mixinDoubts from '@/mixins/doubts';

const THIRTY_SECONDS_IN_MILLISECONDS = 30000;

@Component({
  components: {
    VueEditor,
    FeedbackModal,
    Loading,
  },
})

export default class SendDoubts extends Mixins(mixinDoubts) {
  @Prop({ required: true }) btnText!: string;
  @Prop({ required: true }) send!: Function;
  @Prop({ default: true }) credits!: boolean;
  @Prop() placeholder!: string;
  @Prop({ default: true }) isAvailableSend!: boolean;
  @Prop({ default: true }) select!: boolean;
  @Prop({ default: false }) isMonitorResponse!: boolean;
  @Prop({ default: false }) isShowFeedback!: boolean;
  @Prop({ default: true }) isShowModal!: boolean;

  private content = '';
  private loading = false;

  private setModal = new ActiveModal();
  private responseModal = '';

  private customToolbar = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    ['bold', 'italic', 'underline', { script: 'super' }],

    [{ list: 'ordered' }, { list: 'bullet' }],

    [{ align: [] }],

    ['image'],

    ['clean'],
  ];

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }

  get getIdStudent() {
    return this.$store.getters.profile.idstudent;
  }

  get isDisableButton() {
    return this.loading
      || !this.content
      || (((this.getRemainingCreditsDoubts || 0) <= 0) && this.credits)
      || !this.select
      || !this.isAvailableSend;
  }

  async handleImageAdded(file: any, Editor: any, cursorLocation: any, resetUploader: any) {

    const formData = new FormData();
    formData.append('file', file);

    axios({
      url: `${process.env.VUE_APP_API_STATIC_CDN}/photos`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    })
      .then((result) => {
        const img = new Image();

        img.src = result.data.data.url;

        img.onload = () => {
          Editor.insertEmbed(cursorLocation, 'image', result.data.data.url);

          resetUploader();

          this.$store.dispatch('Toast/setToast', {
            text: 'Imagem carregada com sucesso!',
            status: 'success',
          });
        };

        img.onerror = () => {
          console.error('Error loading image:', result.data.data.url);

          this.$store.dispatch('Toast/setToast', {
            text: 'Erro ao carregar a imagem',
            status: 'error',
            duration: THIRTY_SECONDS_IN_MILLISECONDS,
          });

          this.setTrackAmplitude('Upload Image - handleImageAdded:img.onerror');
        };
      })
      .catch((err) => {
        console.error(err);

        this.$store.dispatch('Toast/setToast', {
          text: 'Erro ao carregar a imagem',
          status: 'error',
          duration: THIRTY_SECONDS_IN_MILLISECONDS,
        });

        this.setTrackAmplitude('Upload Image - handleImageAdded:catch');
      });
  }

  setTrackAmplitude(titleMenu: string) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'SendDoubts',
          local: titleMenu,
        },
      },
    });
  }

  async sendDoubt() {
    try {
      if (!this.hasPermissionCredits) return;

      this.loading = true;

      this.setTrackAmplitude(this.btnText);

      const response = await this.send(this.content);

      if (response) {
        this.content = '';

        if (this.credits) this.incrementTotalDoubts();

        this.responseModal = 'success';
      }
    } catch (error) {
      this.responseModal = 'failure';
      console.error(error);

    } finally {
      this.loading = false;

      if (!this.isMonitorResponse && this.isShowModal) {
        this.$nextTick(() => this.setModal.activeModal('ModalFeedback'));
      }
    }
  }
}
